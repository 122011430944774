<template>
  <div>
    <a-card :bordered="false" class="content" style="width: 100%">
      <a-tag :color="record_type | recordTypeColor">{{
        record_type | recordTypeStatus
      }}</a-tag>
      <a-tag :color="content_type | contentTypeColor">{{
        content_type | contentTypeStatus
      }}</a-tag>
      <span style="margin-left: 10px; color: red"
        >创建时间: {{ changeTime(this.create_time) }}</span
      >
      <span style="margin-left: 10px; color: red"
        >最后更改时间: {{ changeTime(this.update_time) }}</span
      >
      <a-button
        type="primary"
        icon="edit"
        style="float: right; margin-right: 10px"
        @click="
          $router.push({
            path: '/daily_life/edit',
            query: { record_id: record_id },
          })
        "
        >编辑</a-button
      >
    </a-card>
    <a-card :bordered="false" class="content" style="width: 100%">
      <div class="ql-container ql-snow">
        <div class="ql-editor" v-html="content"></div>
      </div>
    </a-card>
    <a-back-top />
  </div>
</template>

<script>
import { changeTime } from "@/libs/utils";
import { getDetailRecord } from "@/api/daily_life";
import consts from "@/constants/daily_life";
export default {
  data() {
    this.changeTime = changeTime;
    return {
      content: "",
      record_id: "",
      record_type: "0",
      content_type: "0",
      create_time: "",
      update_time: "",
    };
  },
  mounted() {
    var record_id = this.$route.query.record_id;
    getDetailRecord(record_id)
      .then((res) => {
        this.content = res.data.record_content;
        this.record_id = res.data.record_id;
        this.record_type = res.data.record_type.toString();
        this.content_type = res.data.content_type.toString();
        this.create_time = res.data.create_time;
        this.update_time = res.data.update_time;
      })
      .catch((err) => {
        this.$message.error(err);
      });
  },
  filters: {
    recordTypeStatus(type) {
      return consts.recordTypeList[type].label;
    },
    recordTypeColor(type) {
      return consts.recordTypeList[type].color;
    },
    contentTypeStatus(type) {
      return consts.contentTypeList[type].label;
    },
    contentTypeColor(type) {
      return consts.contentTypeList[type].color;
    },
  },
};
</script>
